<template>
  <ion-page id="landingPage">
    <ion-content :fullscreen="true">
      <ion-modal
        :animated="false"
        @didDismiss="showRewardNoti = false"
        :is-open="showRewardNoti"
        css-class="tcc-modal"
      >
        <div class="model-box">
          <div class="ion-page">
            <div class="modal-content friend-modal">
              <p class="">
                Congratulations! You have earned a new reward. Go and check it out!
              </p>
              <div class="btn-box" style="display: flex; margin-top: 38px">
                <button class="btn" @click="showRewardNoti = false">OK</button>
                <button
                  class="btn"
                  style="white-space: nowrap; font-size: 14px"
                  @click="jumpReward()"
                >
                  Check my reward
                </button>
              </div>
            </div>
          </div>
        </div>
      </ion-modal>
      <div class="pc-box margin-top-0">
        <div class="pc-min-content-pct">
          <div class="" :class="{ 'fixed-top': !$store.state.isPC }">
            <div class="statusBar" v-if="!$store.state.isPC" ref="statusBar"></div>
            <div class="home-bar" v-if="!$store.state.isPC">
              <img :src="logo" class="logo" />
              <!-- <div class="notification" @click="goNoti">
              <i class="icon-noti"></i>
              <div class="tip-pin"></div>
            </div> -->
            </div>
          </div>
          <div class="loginBar" :class="{ 'padding-top-117': !$store.state.isPC }">
            <ion-button
              expand="block"
              class="Bold"
              style="font-size: 16px"
              v-if="!$store.state.form.token"
              @click="loginBtnEvent"
              >{{ $t("landing.LoginRegister") }}</ion-button
            >
            <div class="hiUser" v-else>
              Hi {{ $store.state.form.userInfo.firstName }}!
            </div>
          </div>
          <!-- Highlight -->
          <div
            class="section banner-content"
            v-if="homePage.length && homePage[0] && homePage[0].length"
          >
            <!-- <div class="section-title Bold" style="padding-top:25px">Highlight</div> -->
            <!-- <ion-slides :pager="$store.state.isPC && homePage[0].length > 1 ? true : false" :options="homePage[0].length > 1 ? slideOptsLoop : slideOpts"> </ion-slides> -->
            <swiper
              :width="$store.state.isPC ? 430 : undefined"
              :slidesPerView="1.1"
              :centeredSlides="true"
              :modules="modules"
              :pagination="$store.state.isPC ? true : false"
              :navigation="
                $store.state.isPC
                  ? {
                      nextEl: '.next1',
                      prevEl: '.prev1',
                    }
                  : false
              "
              @swiper="onSwiper1"
            >
              <swiper-slide v-for="(item, i) in homePage[0]" :key="i">
                <ion-card
                  class="slide-card"
                  :class="!$store.state.isPC ? 'nomargin' : ''"
                  @click="openInAppBrowser(item)"
                >
                  <div class="coverImgBgW">
                    <img :src="item.img" />
                  </div>
                  <ion-card-header class="card-header">
                    <ion-card-subtitle
                      class="subTitle Medium"
                      :class="{ 'letter-ios': $store.state.deviceType == 1 }"
                      >{{ item.tag }}</ion-card-subtitle
                    >
                    <ion-card-title
                      class="cardTitle Bold"
                      :class="{ 'letter-ios': $store.state.deviceType == 1 }"
                      >{{ item.name }}</ion-card-title
                    >
                  </ion-card-header>
                </ion-card>
              </swiper-slide>
            </swiper>
            <div v-if="$store.state.isPC" class="left-btn next1">
              <img src="@/assets/btn-back.png" />
            </div>
            <div v-if="$store.state.isPC" class="right-btn prev1">
              <img src="@/assets/btn-prev.png" />
            </div>
          </div>

          <!-- eCoupon -->
          <div
            class="section banner-content"
            v-if="homePage.length && homePage[1] && homePage[1].length"
          >
            <div
              class="section-title Bold pc-white"
              :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
            >
              {{ $t("landing.eCoupon") }}
            </div>

            <swiper
              :width="$store.state.isPC ? 430 : undefined"
              :centeredSlides="true"
              :slidesPerView="1.1"
              :modules="modules"
              :pagination="$store.state.isPC ? true : false"
              :navigation="
                $store.state.isPC
                  ? {
                      nextEl: '.next2',
                      prevEl: '.prev2',
                    }
                  : false
              "
              @swiper="onSwiper2"
            >
              <swiper-slide v-for="(item, i) in homePage[1]" :key="i">
                <ion-card
                  class="slide-card"
                  style="margin-top: 0px"
                  :class="!$store.state.isPC ? 'nomargin' : ''"
                  @click="goDetail('couponDetail', item.refId)"
                >
                  <!-- <img v-lazy="item.img" :key="item.img" /> -->
                  <div class="coverImgBgW">
                    <img :src="item.img" />
                  </div>
                  <ion-card-header class="card-header">
                    <ion-card-title
                      class="eCouponTitle Bold"
                      :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
                      >{{ item.name }}</ion-card-title
                    >
                    <ion-card-subtitle
                      class="eCouponSubTitle"
                      v-if="$store.state.form.token"
                      >{{ $t("landing.ValidTill") }}
                      {{
                        formatDate(
                          item.endTime.replaceAll("-", "/"),
                          $store.state.form.merchantDetail.dateFormat
                        )
                      }}</ion-card-subtitle
                    >
                  </ion-card-header>
                </ion-card>
              </swiper-slide>
            </swiper>
            <div v-if="$store.state.isPC" class="left-btn next2">
              <img src="@/assets/btn-back.png" />
            </div>
            <div v-if="$store.state.isPC" class="right-btn prev2">
              <img src="@/assets/btn-prev.png" />
            </div>
          </div>

          <!-- Collector Cards -->
          <div
            class="section banner-content"
            v-if="homePage.length && homePage[2] && homePage[2].length"
          >
            <div
              class="section-title pc-white Bold"
              :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
            >
              {{ $t("landing.CollectorCards") }}
            </div>

            <swiper
              :centeredSlides="true"
              :width="$store.state.isPC ? 430 : undefined"
              :slidesPerView="1.1"
              :modules="modules"
              :pagination="$store.state.isPC ? true : false"
              :navigation="
                $store.state.isPC
                  ? {
                      nextEl: '.next3',
                      prevEl: '.prev3',
                    }
                  : false
              "
              @swiper="onSwiper3"
            >
              <swiper-slide v-for="(item, i) in homePage[2]" :key="i">
                <ion-card
                  class="slide-card"
                  :class="!$store.state.isPC ? 'nomargin' : ''"
                  @click="goDetail('collectorCardDetail', item.userStampCardId)"
                >
                  <div class="coverImgBgW">
                    <img :src="item.img" />
                  </div>
                  <ion-card-header class="card-header">
                    <ion-card-title
                      class="eCouponTitle Bold"
                      :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
                      >{{ item.name }}</ion-card-title
                    >
                    <div class="center-item">
                      <ion-card-subtitle class="eCouponSubTitle"
                        >{{ $t("landing.ValidTill") }}
                        {{
                          formatDate(
                            item.endTime.replaceAll("-", "/"),
                            $store.state.form.merchantDetail.dateFormat
                          )
                        }}</ion-card-subtitle
                      >
                      <div class="row-item" v-if="$store.state.form.token">
                        <img v-lazy="item.stampImg" :key="item.stampImg" />
                        <div class="stamp">
                          {{ item.collectStampQty }}/{{ item.singleCardCollStampQty }}
                        </div>
                      </div>
                    </div>
                  </ion-card-header>
                  <div
                    class="collect-card-list"
                    v-if="item.collectList && item.collectList.length > 0"
                  >
                    <div
                      class="card-item"
                      v-for="(card, cardIndex) in item.collectList"
                      :key="cardIndex"
                    >
                      <div class="card-num" v-show="!card.stampImg">
                        {{ card.num }}
                      </div>
                      <div class="card-itemImg" v-show="card.stampImg">
                        <img v-lazy="card.stampImg" :key="card.stampImg" />
                      </div>
                    </div>
                  </div>
                </ion-card>
              </swiper-slide>
            </swiper>
            <div v-if="$store.state.isPC" class="left-btn next3">
              <img src="@/assets/btn-back.png" />
            </div>
            <div v-if="$store.state.isPC" class="right-btn prev3">
              <img src="@/assets/btn-prev.png" />
            </div>
          </div>

          <!-- Stamp -->
          <div
            class="section"
            v-if="homePage.length && homePage[3] && homePage[3].length"
          >
            <div
              class="section-title pc-white Bold"
              :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
            >
              eStamps
            </div>
            <swiper
              :centeredSlides="true"
              :width="$store.state.isPC ? 430 : undefined"
              :slidesPerView="1.1"
              @swiper="onSwiper4"
            >
              <swiper-slide v-for="(item, i) in homePage[3]" :key="i">
                <ion-card
                  class="slide-card"
                  :class="!$store.state.isPC ? 'nomargin' : ''"
                  @click="goDetail('stampCardDetail', item.refId)"
                >
                  <!-- <img v-lazy="item.img" :key="item.img" /> -->
                  <div class="coverImgBgW">
                    <img :src="item.img" />
                  </div>
                  <ion-card-header class="card-header">
                    <ion-card-title
                      class="eCouponTitle Bold"
                      :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
                      >{{ item.name }}</ion-card-title
                    >
                    <div class="center-item">
                      <ion-card-subtitle class="eCouponSubTitle"
                        >{{ $t("landing.ValidTill") }}
                        {{
                          formatDate(
                            item.endTime.replaceAll("-", "/"),
                            $store.state.form.merchantDetail.dateFormat
                          )
                        }}</ion-card-subtitle
                      >
                      <div class="row-item stampItem" v-if="$store.state.form.token">
                        <img src="@/assets/stamp-no.png" />
                        <div class="stampNo Bold">
                          {{ item.collectStampQty ? item.collectStampQty : 0 }}
                        </div>
                      </div>
                    </div>
                  </ion-card-header>
                </ion-card>
              </swiper-slide>
            </swiper>
          </div>
          <div class="height50"></div>
        </div>
      </div>
      <walletBtn />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCard,
  IonModal,
  IonCardHeader,
  IonButton,
} from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import config from "../config";
import walletBtn from "@/components/walletBtn.vue";
export default {
  name: "landingPage",
  mixins: [config.globalMixin],
  data() {
    return {
      slideOpts: {
        initialSlide: 0,
        width: this.$store.state.isPC ? "430" : undefined,
        centeredSlides: true,
      },
      slideOptsLoop: {
        autoplay: false,
        loop: false,
        initialSlide: 0,
        centeredSlides: true,
      },
      stampLoop: {
        autoplay: false,
        width: this.$store.state.isPC ? "430" : "380",
        loop: false,
        initialSlide: 0,
        centeredSlides: true,
      },
      stampOpts: {
        initialSlide: 0,
        width: this.$store.state.isPC ? "430" : "380",
        centeredSlides: true,
      },
      modules: [Navigation, Pagination],
      logo: require("@/assets/logo.png"),
      heightLightList: [],
      eCouponList: [],
      collectCardList: [],
      stampList: [],
      homePage: [],
      cardData: [],
      swiper1: null,
      swiper2: null,
      swiper3: null,
      swiper4: null,
      showRewardNoti: false,
    };
  },
  components: {
    IonContent,
    IonPage,
    walletBtn,
    IonCardSubtitle,
    IonCardTitle,
    IonCard,
    IonCardHeader,
    IonButton,
    Swiper,
    SwiperSlide,
    IonModal,
  },
  methods: {
    init() {
      this.getHomePageEvent();
    },
    async getHomePageEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.listHomePage;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (
        this.$store.state.form.token == "" &&
        (data.data.errorCode == 10402 || data.data.errorCode == 10401)
      ) {
        this.$store.state.form.token = "";
        this.setStorageEvent();
        this.getHomePageEvent();
        return;
      }

      if (data.data.errorCode == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (this.$store.state.form.token) {
        this.saveUserAllCoupon();
        // this.initUserStampCard();
      }
      this.dataEvent(data.data.result);
      this.$store.state.form.listHomePage = data.data.result;
    },
    onSwiper1(swiper) {
      this.swiper1 = swiper;
    },
    onSwiper2(swiper) {
      this.swiper2 = swiper;
    },
    onSwiper3(swiper) {
      this.swiper3 = swiper;
    },
    onSwiper4(swiper) {
      this.swiper4 = swiper;
    },
    dataEvent(data) {
      const homePage = JSON.parse(data.homePage);
      if (!homePage) {
        return;
      }
      this.records = data.records;
      for (let i = 0; i < this.records.length; i++) {
        for (let j = 0; j < homePage.length; j++) {
          for (let k = 0; k < homePage[j].length; k++) {
            if (
              homePage[j][k].refId == this.records[i].refId &&
              homePage[j][k].type == this.records[i].type
            ) {
              homePage[j][k] = this.records[i];
            }
          }
        }
      }
      homePage.forEach((home) => {
        for (let i = home.length - 1; i >= 0; i--) {
          if (!home[i].id) {
            home.splice(i, 1);
          }
        }
      });
      if (this.$store.state.form.token) {
        let itemsPerPage = 10;

        homePage[2].forEach((item) => {
          data.stampCards.forEach((card) => {
            if (item.refId == card.stampCardId) {
              item.collectStampQty = card.collectStampQty;
              item.singleCardCollStampQty = card.singleCardCollStampQty;
              item.userStampCardId = card.userStampCardId;
              item.stampImg = card.stampImg;

              item.collectList = [];
              let currentPage =
                item.collectStampQty != 0
                  ? Math.ceil(item.collectStampQty / itemsPerPage)
                  : 1;
              console.log(item.collectStampQty);

              let start, end, imgTimes;

              if (currentPage === 1) {
                start = 1;
                end = Math.min(item.singleCardCollStampQty, itemsPerPage);
              } else {
                start = itemsPerPage * (currentPage - 1) + 1;
                end = Math.min(itemsPerPage * currentPage, item.singleCardCollStampQty);
              }
              imgTimes = item.collectStampQty - start + 1;
              console.log(start, end);

              for (let i = 0; i < 10; i++) {
                item.collectList[i] = {};
                if (i < imgTimes) {
                  item.collectList[i].stampImg = item.stampImg;
                }
                item.collectList[i].num = start + i;
              }
            }
          });
        });
      }
      homePage[0] = homePage[0].slice(0, 10);
      this.homePage = homePage;
    },
    goDetail(type, id) {
      this.$store.state.form.backPage = "/main/landing";
      if (type == "couponDetail") {
        this.$router.push({
          path: "/" + type + "/" + id,
        });
        return;
      }
      this.checkToken("/main/landing", () => {
        this.$router.push({
          path: "/" + type + "/" + id,
        });
      });
      this.sendGAEvent("Click", "Home", type, id);
    },
    jumpReward() {
      this.showRewardNoti = false;
      this.$store.state.isReward = true;
      this.$router.push({
        path: "/main/offers",
      });
    },
    async checkRewardNoti() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.getUserNewRewardNotifi;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (data.data.result.isPopup) {
        this.showRewardNoti = true;
      }
    },
    loginBtnEvent() {
      this.$router.push({
        path: "/main/me",
      });
      this.sendGAEvent("Click", "Home", "Log in / Register");
    },
    async saveUserAllCoupon() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.saveUserAllCoupon;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
    },
    async initUserStampCard() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.initUserStampCard;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
    },
    goNoti() {
      this.$router.push({
        path: "/notification",
      });
    },
  },
  ionViewDidEnter() {
    // setTimeout(() => {
    //   let hi1 = $("#me-page").attr("class").search("ion-page-hidden") > -1 || false;

    // if (this.$route.path == "/main/landing") {
    //   $("#offers-page").addClass("ion-page-hidden");
    //   $("#offers-page").css("zIndex", "100");
    //   $("#more-page").addClass("ion-page-hidden");
    //   $("#more-page").css("zIndex", "100");
    //   $("#me-page").addClass("ion-page-hidden");
    //   $("#me-page").css("zIndex", "100");
    //   $("#landingPage").removeClass("ion-page-hidden");
    //   $("#landingPage").css("zIndex", "101");
    // }
    // }, 100);
    this.$store.state.exitApp = true;
    if (this.$store.state.connected) {
      this.getMerchantDetail(this.init);
      if (this.$store.state.form.token) {
        this.getMemberDetail(true, 0);
      }
    } else {
      this.dataEvent(this.$store.state.form.listHomePage);
    }
    if (this.$store.state.form.token) {
      this.checkRewardNoti();
    }
    if (this.swiper1) {
      this.swiper1.slideTo(0);
    }
    if (this.swiper2) {
      this.swiper2.slideTo(0);
    }
    if (this.swiper3) {
      this.swiper3.slideTo(0);
    }
    if (this.swiper4) {
      this.swiper4.slideTo(0);
    }
    this.clickTabEvent("landing");
    this.sendGAPageView("Home");
  },
  ionViewWillEnter() {
    // console.log("landing", new Date().getTime());
  },
  //   ionViewWillEnter() {
  //     setTimeout(() => {
  //       //   let hi1 = $("#me-page").attr("class").search("ion-page-hidden") > -1 || false;

  //       if (this.$route.path == "/main/landing") {
  //         $("#offers-page").addClass("ion-page-hidden");
  //         $("#offers-page").css("zIndex", "100");
  //         $("#more-page").addClass("ion-page-hidden");
  //         $("#more-page").css("zIndex", "100");
  //         $("#me-page").addClass("ion-page-hidden");
  //         $("#me-page").css("zIndex", "100");
  //         $("#landingPage").removeClass("ion-page-hidden");
  //         $("#landingPage").css("zIndex", "101");
  //       }
  //     }, 100);
  //   },
};
</script>

<style scoped lang="scss">
.slides-md {
  --bullet-background-active: #fff !important;
}
.collect-card-list {
  padding-left: 5%;
  height: 120px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  place-content: center;
  .card-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 21px;
    color: #b6b6b6;
    box-sizing: content-box;
    border: 2px dashed #c5c5c5;
    border-radius: 50%;
    text-align: center;
    .card-num {
    }
    .card-itemImg {
      width: 36px;
      height: 36px;
      img {
        width: 100px;
        height: 100%;
      }
    }
  }
}
</style>
